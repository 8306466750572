import * as React from 'react';
import { AppBar, Grid, Box, Container, Toolbar, Typography, Link, Fab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Email as EmailIcon } from '@mui/icons-material';
import Typewriter from 'typewriter-effect';
import CssBaseline from '@mui/material/CssBaseline';
import EffectuateCorpLogo from './assets/icons/effectuate-corp.svg';
import './Scrollbars.css';
import '@fontsource-variable/inter';
import '@fontsource/libre-caslon-text';

function App() {

	const theme = createTheme({
		Typography: {
			fontFamily: [
				'"Inter Variable"'
			]
		},
		palette: {
			mode: 'light',
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
				body: {
					background: "rgb(255,255,255)",
					backgroundImage: "linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(239,255,250,1) 25%, rgba(229,236,244,1) 50%, rgba(195,190,247,1) 75%, rgba(138,79,255,1) 100%)",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
				},
				},
			},
		}
	})

  return (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<Box>
			<AppBar position="fixed" sx={{ background: 'transparent', boxShadow: 'none' }}>
				<Toolbar>
					<img src={EffectuateCorpLogo} alt="logo" height='40vh' />
				</Toolbar>
			</AppBar>
			<Container maxWidth='xl'>
				<Grid container spacing={0} direction='column' justifyContent='center' sx={{ minHeight:'100vh' }}>
					<Grid item md={12} sx={{ minWidth: 0 }}>
						<Box>
							<Typography variant='h2' sx={{ fontFamily: 'Libre Caslon Text' }}>
								<Typewriter
									options={{
									strings: ['We are Engineers.', 'We make modern software.'],
									autoStart: true,
									loop: true,
									delay: 50,
									pauseFor: 500,
									deleteSpeed: 10,
									}}
								/>
							</Typography>
							<Typography variant='h4'>
								Great things coming soon.
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<AppBar position="fixed" sx={{ flexGrow: 1, alignItems: 'center', background: 'transparent', boxShadow: 'none', bottom: 0, top: 'auto' }}>
				<Toolbar>
					<Typography paragraph sx={{color: 'black'}}>
						Made with ❤️ by <Link href='https://www.yashj.ca' color='inherit'>Yash</Link>
					</Typography>
				</Toolbar>
			</AppBar>
			<Fab sx={{ margin: 3, top: 'auto', right: 0, bottom: 0, left: 'auto', position: 'fixed' }} href='mailto:hello@effectuatecorp.com'>
				<EmailIcon />
			</Fab>
		</Box>
	</ThemeProvider>
  );
}

export default App;
